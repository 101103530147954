<template>
  <div :class="{ 'content-width': fitContent }" class="table-note-field">
    <div
      v-if="currentMode === modes.VIEW"
      class="value-container"
      :title="editableValue"
      @click="setEditMode"
    >
      {{ containerValue }}
    </div>
    <input
      v-if="currentMode === modes.EDIT"
      class="input-field"
      :class="{ 'error-state': errorState }"
      type="text"
      ref="inputField"
      :placeholder="'Type note here'"
      v-model="editableValue"
      @focusout="handleInputBlur"
      maxlength="35"
    />
  </div>
</template>

<script>
export default {
  props: [
    'value',
    // Disables fixed width to the container and takes width equal to content. BYB-466
    'fitContent',
  ],
  data() {
    return {
      modes: {
        VIEW: 'VIEW',
        EDIT: 'EDIT',
      },
      currentMode: 'VIEW',
      editableValue: '',
      errorState: false,
    }
  },
  created() {
    this.currentMode = !this.value ? this.modes.EDIT : this.modes.VIEW
    this.editableValue = this.value || ''
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue) {
        this.editableValue = newValue
        this.currentMode = this.modes.VIEW
      }
    },
  },
  computed: {
    containerValue() {
      return this.editableValue
    },
  },
  methods: {
    setEditMode() {
      this.currentMode = this.modes.EDIT
    },
    handleInputBlur() {
      this.errorState = false
      this.editableValue = this.editableValue.trim()

      if (this.editableValue) {
        this.currentMode = this.modes.VIEW
      }

      if (this.editableValue !== this.value) {
        this.$emit('pipelineNoteUpdated', this.editableValue)
        this.errorState = false
        return
      }
      if (this.editableValue && this.editableValue === this.value) {
        this.currentMode = this.modes.VIEW
        this.errorState = false
        return
      }
      this.editableValue = this.value
      this.currentMode = this.modes.EDIT
    },
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.table-note-field {
  height: 20px;
  outline: none;
  overflow: hidden;

  .value-container {
    display: block;
    flex-direction: column;
    align-items: start;
    gap: 6px;
    height: 100%;
    max-width: 100%;
    font-size: $fontSizeStandart;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .input-field {
    width: 250px;
    height: 20px;
    padding: 3px;
    font-size: $fontSizeStandart;
    box-sizing: border-box;
    border: 1px solid $grayBorderColor;
    border-radius: 4px;
    outline: none;
    &::placeholder {
      font-size: $fontSizeMinified;
    }
  }

  .error-state {
    border: 1px solid $redWarningColor;
    border-radius: 4px;
  }
}

.content-width {
  width: fit-content;
  max-width: 410px;
}
</style>
